import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Media } from '../models/media';
import { catchError, map, share, shareReplay, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaService {
  //changelog: above cut over to call by GetByMediaId after adding a new route to web api on 12-1-21
  //this should reduce the amount of data I pull down based on the updated Get call to only request by mediaId rather than
  //'getting' all the media in the table and then filtering below in mediaItems.filter(({ mediaId }) => mediaId === mediaID)
  mediaItems!: Media[];

  constructor(private readonly http: HttpClient) {}
  getMediaItems(mediaID: string): Observable<Media[]> {
    return (
      this.http
        .get<Media[]>(
          `${environment.API.BASE_URL}/${environment.media}/${mediaID}`
        )
        .pipe(
          tap((mediaItems: Media[]) => {
            this.mediaItems = mediaItems;
          }),
          shareReplay(), //added by Michael.... read up on this

          // TODO: GET RID OF EVERYTHING BETWEEN MAP START AND MAP END BLOCK. DONE 12-1-21
          // MAP - START
          // map((mediaItems) =>
          //   mediaItems.filter(({ mediaId }) => mediaId === mediaID)
          // ),
          // MAP END
          shareReplay(),
          catchError(this.handleError<Media[]>('getMediaItems', []))
        )
    );

    //debug only
    // console.log(this.mediaItems);
  }

  /** handle Http operation failed.   */
  private handleError<T>(opertion = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      //TODO: send error to remote logging infrastructure
      this.log('error in getMediaItems');
      console.error(error); //log to console
      this.log(error.message);

      return of(result as T);
    };
  }

  /** Log a message with injected MessageService by creating a wrapper since we call it so much */
  private log(message: string): void {
    /**this.messageService.add(`Mobilehome.service: ${message}`);*/
    console.log(message);
  }
}
